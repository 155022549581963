import React from "react"
import BodyClassName from "react-body-classname"

import { ucwords } from "lib/utils"
import HeaderLink from "components/HeaderLink"
import HeaderButton from "components/HeaderButton"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMessage } from "@fortawesome/free-solid-svg-icons"
import styles from "./HeaderLoggedOut.module.scss"
import HeaderRightContent from "components/HeaderRightContent"
import DesktopOnly from "components/DesktopOnly"
import MobileOnly from "components/MobileOnly"
import HeaderContainer from "components/HeaderContainer"
import HeaderWithLogo from "components/HeaderWithLogo"
import HeaderUserInfo from "components/HeaderUserInfo"

const HeaderLoggedOut = () => {
  const userToken = localStorage.getItem("userToken")
  const tokenParams = JSON.parse(userToken)

  const _openWidget = (e) => {
    const classes = document.querySelector("body").className.split(" ")
    if (classes.indexOf("widget--on") < 0) {
      classes.push("widget--on")
      document.querySelector("body").className = classes.join(" ")
    }
    window.Intercom("show")
  }

  const _getBodyClass = () => {
    const userToken = localStorage.getItem("userToken")
    if (userToken) {
      return "header--user"
    }
    return ""
  }

  return (
    <BodyClassName className={_getBodyClass()}>
      <HeaderContainer>
        <HeaderWithLogo>
          <DesktopOnly>
            <HeaderRightContent>
              <HeaderLink
                href="/how-simply-bail-works"
                text="How Simply Bail Works"
              />
              <HeaderLink href="/how-simply-bail-works#faq" text="FAQ" />
              <HeaderLink
                href="/how-bail-bonds-work"
                text="How Bail Bonds Work"
              />
            </HeaderRightContent>
          </DesktopOnly>
          <MobileOnly>
            <HeaderRightContent>
              <HeaderButton onClick={_openWidget} className={styles.buttHelp}>
                <FontAwesomeIcon icon={faMessage} />
              </HeaderButton>
            </HeaderRightContent>
          </MobileOnly>
        </HeaderWithLogo>
        {userToken && (
          <HeaderUserInfo
            token={tokenParams.token}
            name={ucwords(`${tokenParams.nameFirst} ${tokenParams.nameLast}`)}
            bondsman={tokenParams.bondsman}
          />
        )}
      </HeaderContainer>
    </BodyClassName>
  )
}

export default HeaderLoggedOut
