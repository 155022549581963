import React from "react"
import PropTypes from "prop-types"
import styles from "./FooterLink.module.scss"

const FooterLink = ({ href, text, className, displayBlock }) => {
  return (
    <a
      className={`${styles.link} ${className} ${displayBlock && styles.block}`}
      href={href}
    >
      {text}
    </a>
  )
}

FooterLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  /**
   * any additional class names to apply
   */
  className: PropTypes.string,
  /**
   * if true, adds display:block to the <a> tag style
   */
  displayBlock: PropTypes.bool
}

FooterLink.defaultProps = {
  href: undefined,
  text: undefined,
  className: undefined,
  displayBlock: undefined
}

export default FooterLink
