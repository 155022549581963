import React from "react"
import PropTypes from "prop-types"
import styles from "./FooterButton.module.scss"

const FooterButton = ({ onClick, children, className, displayBlock }) => {
  return (
    <button
      className={`${styles.butt} ${className} ${displayBlock && styles.block}`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

FooterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  /**
   * any additional class names to apply
   */
  className: PropTypes.string,
  /**
   * if true, adds display:block to the <a> tag style
   */
  displayBlock: PropTypes.bool
}

FooterButton.defaultProps = {
  onClick: undefined,
  children: undefined,
  className: undefined,
  displayBlock: undefined
}

export default FooterButton
