import React, { useState, useEffect } from 'react'

// components
import { Menu, Skeleton } from 'antd'
import ButtonPrimary from 'components/ButtonPrimary'
import Layout from 'components/Layout'

// utils
import api from 'lib/api'
import { discectError } from "lib/utils"

// styles
import styles from './styles.module.scss'
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"

const LearningCenter = props => {
  const [isLoading, setIsLoading] = useState(false) // eslint-disable-line
  const [erorr, setError] = useState(false) // eslint-disable-line
  const [videos, setVideos] = useState(null)
  const [currentVideo, setCurrentVideo] = useState(null)

  const _fetchVideos = async () => {
    setIsLoading(true)
    try {
      let res = await api.bondsman.fetchLearningCenterVideos()
      
      const formatResponse = (obj) => {
        if (obj.children.length > 0) {
          return {
            children: obj.children.map((itm) => formatResponse(itm)),
            label: obj.label,
            key: obj.id
          }
        } else {

          if(obj.label === 'INTRODUCTION') return null

          return {
            type: "group",
            children: obj.videos.map((itm, key) => ({ ...itm, label: itm.name, key: `${obj.id}_${key}` })),
            label: obj.label,
            key: obj.id
          }
        }
      }
      setVideos(formatResponse(res.data))
    } catch(e) {
      console.error(e)
      setError(discectError(e))
    }
    setIsLoading(false)
  }

  const _getAllKeys = (items) => {
    let keys = []
    if(items) {
      items?.forEach(item => {
        if (item?.children && item?.children.length > 0) {
          keys.push(item.key)
          keys = keys.concat(_getAllKeys(item.children))
        }
      })
    }
    return keys
  }

  useEffect(() => {
    if (!videos) _fetchVideos()
  }, []) // eslint-disable-line

  const _handleClick = (e) => {
    const { item } = e
    const { name, player_embed_url } = item.props
    setCurrentVideo({ name, player_embed_url})
  }

  const _menuSkeleton = () => {
    return (
      <div className={styles.skeleton}>
        <Skeleton.Input active size="large" block/>
        <Skeleton.Input active size="large" block/>
        <Skeleton.Input active size="large" block/>
        <Skeleton.Input active size="large" block/>
        <Skeleton.Input active size="large" block/>
        <Skeleton.Input active size="large" block/>
        <Skeleton.Input active size="large" block/>
        <Skeleton.Input active size="large" block/>
      </div>
    )
  }

  const _defaultScreen = () => {

    return (
      <div className={styles.defaultScreen}>
        <div>
          <i className="fa-regular fa-video-arrow-up-right"></i>
          <h3>Select A Video On the Left</h3>
          <p>Explore how to use Simlpy Bail</p>
        </div>
      </div>
    )

  }
  
  return (
    <Layout bodyClassName="layout--learningCenter">
      <div className={styles.learningCenter}>
        <aside className={styles.learningCenter_sideBar}>
          <h1>Learning Center</h1>
          <div className="learningCenter__linksContainer">
            {!videos && _menuSkeleton()}
            {videos && <Menu onClick={_handleClick} mode="inline" defaultOpenKeys={_getAllKeys([videos])} items={[videos]}/>}
          </div>
        </aside>
        <div className={styles.learningCenter_content}>
          <div className={styles.learningCenter_content_head}>
            <div>
              <h2>{currentVideo?.name || null}</h2>
              <p>{currentVideo?.name ? "Video" : null}</p>
            </div>
            <div>
            <ButtonPrimary color="clear" icon={faChevronLeft} gotoUrl={`/agent/dashboard`}>Back to Simply Bail</ButtonPrimary>
            </div>
          </div>
          <div className={styles.learningCenter_content_vid}>
            {currentVideo && (
              <iframe
                style={{ height:'100%', width:'100%' }}
                width="100%"
                height="100%"
                src={currentVideo?.player_embed_url}
                title={currentVideo?.name}
                frameborder="0"
                allowFullscreen={true}
              ></iframe>
            )}
            {!currentVideo && _defaultScreen()}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LearningCenter
