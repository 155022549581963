import React, { useEffect } from 'react'

import Layout from 'components/Layout'
import Footer from 'components/Footer'

import analytics from 'lib/analytics'

const Page = props => {

  useEffect(() => analytics.notFoundEvent(), [])

  return (
    <Layout bodyClassName="layout--notFound">
      <div className="notFound__container">
        <h2>Not Found!</h2>
        <p>We could not find the page you are looking for!</p>
      </div>
      <Footer />
    </Layout>
  )
}

export default Page