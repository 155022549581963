import React from "react"
import PropTypes from "prop-types"
import styles from "./MobileOnly.module.scss"

const MobileOnly = ({ children }) => {
  return <div className={styles.mobileOnly}>{children}</div>
}

MobileOnly.propTypes = {
  children: PropTypes.node.isRequired
}

MobileOnly.defaultProps = {
  children: undefined
}

export default MobileOnly
