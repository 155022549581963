const { gql } = require('graphql-request')


export const BILL_PAYMENT_FRAGMENT = gql`
fragment billPayment on Payment {
  id
  breakdown {
    total
    totalAmount
    surchargeAmount
  }
}`

export const BILL_FRAGMENT = gql`
fragment billInfo on Bill {
  id
  agencyName
  createdAt
  email
  errorMessage
  payments {
    ...billPayment
  }
  employee {
    id
    nameFirst
    nameLast
  }
  phone
  status
  token
  tokenExpiry
  victim {
    caseId
    id
  }
  name
  invoiceTotalCents
  invoiceId
  invoiceDate
  isPaid
  lastSentDate
}
${BILL_PAYMENT_FRAGMENT}`