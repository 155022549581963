import React from 'react'
import validator from 'card-validator'
import { faBan, faCheckCircle } from "@fortawesome/free-solid-svg-icons"

import Input from 'components/Input'
import Toggle from 'components/Toggle'
import ButtonPrimary from 'components/ButtonPrimary'

import { discectError, fetchLocation, isValid, validPhoneNumber, validEmail } from 'lib/utils'

import styles from './styles.module.scss'

class TokenizedPaymentForm extends React.Component {

  state = {
    error: null,
    validCardFields: { ccnumber: false, ccexp: false, cvv: false },
    cardValidation: {
      number: { isValid: false },
      expirationDate: { isValid: false },
      cvv: { isValid: false },
      zip: { isValid: false }
    },
    info: {}
  }

  componentDidMount() {
    if (window.CollectJS) {
      window.CollectJS.configure({
        variant: 'inline',
        styleSniffer: false,
        googleFont: 'Rubik:400',
        customCss: {
          'padding': '10px',
          'border': '1px solid #DFE6F4',
          'border-radius': '5px',
          'font-size': '14px',
          'font-weight': '400',
          'outline': 'none',
          '-webkit-appearance': 'none',
          'font-family': 'Rubik, sans-serif',
          '-webkit-font-smoothing': 'antialiased'
        },
        focusCss: {
          'border-color': '#24C0AC',
          'outline-style': 'none'
        },
        validationCallback: this._validationCallback,
        callback: this._finishSubmit,
        timeoutCallback: () => {
          console.error('Timeout...')
        },
        fields: {
          ccnumber: {
            selector: '#ccnumber'
          },
          ccexp: {
            selector: '#ccexp'
          },
          cvv: {
            selector: '#cvv'
          }
        }
      })
    }
    this._validationCallback.bind(this)
    this._finishSubmit.bind(this)

    if (this.props.info?.info) {
      this.setState({ info: this.props.info.info })
    }
  }

  setError(error) {
    this.setState({ error })
  }

  setInfo(key, val) {
    this.setState(v => { return { ...v, info: { ...v.info, [key]: val } } })
  }

  _validationCallback = (field, valid, message) => {
    const { validCardFields } = this.state

    this.setState({ validCardFields: { ...validCardFields, [field]: valid } })
  }
  
  _finishSubmit = response => {
    this.props.setIsLoading(false)

    const { info } = this.state

    if (this.props.onContinue) {
      this.props.onContinue({ ...response, info })
    } else {
      console.error('No valid callback provided.')
    }
  }

  _getCardType = payload => {
    if (['visa','mastercard'].indexOf(payload.scheme) >= 0) {
      if (payload.type === 'debit') {
        if (payload.scheme === 'visa') return 'VISA DEBIT'
        if (payload.scheme === 'mastercard') return 'DEBIT MASTERCARD'
      }
    }
    return payload.scheme.toUpperCase()
  }

  _onSubmit = async e => {
    const { validCardFields, info } = this.state

    this.props.setIsLoading(true)
    
    try {
      if (validCardFields.ccnumber && validCardFields.ccexp && validCardFields.cvv) {
        if (isValid(info, ['nameFirst','nameLast','email','phone','address','city','state','zip'])) {
          if (!validPhoneNumber(info.phone)) throw new Error('Phone is invalid')
          if (!validEmail(info.email)) throw new Error('Email is invalid')
          window.CollectJS.startPaymentRequest(e)
        } else {
          throw new Error('Please make sure all of the required information is correct.')
        }
      } else {
        throw new Error('The card you are using is not valid. Please try another card.')
      }
    } catch(e) {
      console.error(e.message)
      this.setError(discectError(e))
      window.scrollTo(0,0)
      this.props.setIsLoading(false)
    }
  }

  _handleChangeCardNumber = e => {
    let { cardValidation, validCardFields } = this.state

    cardValidation.number = validator.number(e.target.rawValue)
    validCardFields.ccnumber = e.target.rawValue

    this.setState({ cardValidation, validCardFields })
  }

  _handleExpiryChange = e => {
    let { cardValidation, validCardFields } = this.state

    cardValidation.expirationDate = validator.expirationDate(e.target.value)
    validCardFields.ccexp = e.target.value

    this.setState({ cardValidation, validCardFields })
  }

  _handleCvvChange = (key, val) => {
    let { cardValidation, validCardFields } = this.state
    validCardFields.cvv = val
    this.setState({ cardValidation, validCardFields })
  }

  _onTextChange = async (key, val) => {
    let { cardValidation, validCardFields } = this.state
    
    this.setInfo(key, val)

    if (key === 'zip') {
      validCardFields.zip = val
      cardValidation.zip = validator.postalCode(val)

      if (val.length === 5) {
        try {
          const location = await fetchLocation(val)
          if (!location.error && location.city && location.state) {
            this.setState(v => { return { ...v, info: { ...v.info, ...location } } })
          } else {
            this.setError('Location could not be found.')
          }
        } catch(e) {
          const error = (e.error.toLowerCase() === 'place not found.') ? 'Zip code is invalid, please try again.' : e.error
          console.error(error)
          this.setError(error)
        }
      }

      this.setState({ cardValidation, validCardFields })
    }
  }

  _isDisabled() {
    const { info, validCardFields } = this.state

    if (validCardFields.ccnumber && validCardFields.ccexp && validCardFields.cvv) {
      if (isValid(info, ['nameFirst','nameLast','email','phone','address','city','state','zip'])) {
        if (!validPhoneNumber(info.phone)) return true
        if (!validEmail(info.email)) return true
        return false
      }
    }
    return true
  }

  render() {
    const { info } = this.state

    return (
      <div className={styles.paymentForm}>
        <form onSubmit={e => e.preventDefault()}>
          <h5 className="rad">Billing Info</h5>
          <div className={styles.paymentForm__row}>
            <Input type="text" id="nameFirst" label="First Name" value={info?.nameFirst || null} onChange={this._onTextChange} isRequired />
            <Input type="text" id="nameLast" label="Last Name" value={info?.nameLast || null} onChange={this._onTextChange} isRequired />
          </div>
          <div className={styles.paymentForm__row}>
            <Input type="text" id="email" label="Email" value={info?.email || null} onChange={this._onTextChange} isRequired />
            <Input type="tel" id="phone" label="Phone" value={info?.phone || null} onChange={this._onTextChange} isRequired />
          </div>
          <div className={`${styles.paymentForm__row} ${styles.paymentForm__row__address}`}>
            <Input type="text" id="address" label="Address" value={info?.address || null} onChange={this._onTextChange} isRequired />
            <Input type="text" id="zip" label="Zip Code" value={info?.zip || null} onChange={this._onTextChange} isRequired />
          </div>
          <h5 className="rad">Card Information</h5>
          <div className="formRow">
            <div className="formRow__block">
              <label className="required">Credit Card Number</label>
              <div id="ccnumber" />
            </div>
          </div>
          <div className={styles.paymentForm__row}>
            <div className="formRow__block">
              <label className="required">Expiration Date</label>
              <div id="ccexp" />
            </div>
            <div className="formRow__block">
              <label className="required">CVV</label>
              <div id="cvv" />
            </div>
          </div>
          {!this.props.isSaveCardHidden && (
            <div className={styles.paymentForm__row}>
              <Toggle id="saveCard" name="saveCard" checked={info.saveCard || false} text="Would you like to save this card for future payments?" isYesNo onChange={this._onTextChange} />
            </div>
          )}
          {process.env.REACT_APP_NODE_ENV !== 'production' && (
            <div className="cardExamples">
              <p>Use the cards below for testing the application. Also found at <a rel="noopener noreferrer" href="https://support.nmi.com/hc/en-gb/articles/115002375583-Test-Cards" target="_blank">NMI Test Cards</a></p>
              <div><span>4111111111111111</span> Standard Card - Success</div>
              <div><span>4539791001730106</span> Standard Card - Success</div>
              <div><span>4123450131003312</span> Standard Card - Success</div>
              <div><span>341111597241002</span> Standard Card - Success</div>
              <div><span>4242424242424242</span> Standard Card - Decline</div>
            </div>
          )}
          <div className={styles.actions}>
            {this.props.onBack && <ButtonPrimary color="grey" icon={faBan} onClick={() => this.props.onBack()}>Back</ButtonPrimary>}
            <ButtonPrimary color="navy" icon={faCheckCircle} disabled={this._isDisabled()} onClick={() => this._onSubmit()}>Continue</ButtonPrimary>
          </div>
        </form>
      </div>
    )
  }
}

export default TokenizedPaymentForm