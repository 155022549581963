import React from "react"
import PropTypes from "prop-types"
import styles from "./HeaderButton.module.scss"

const HeaderButton = ({ onClick, children, className }) => {
  return (
    <button className={`${styles.butt} ${className}`} onClick={onClick}>
      {children}
    </button>
  )
}

HeaderButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  /**
   * any additional class names to apply
   */
  className: PropTypes.string
}

HeaderButton.defaultProps = {
  onClick: undefined,
  children: undefined,
  className: undefined
}

export default HeaderButton
