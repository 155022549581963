import React from "react"
import PropTypes from "prop-types"
import styles from "./HeaderContainer.module.scss"

const HeaderContainer = ({ children }) => {
  return <div className={styles.container}>{children}</div>
}

HeaderContainer.propTypes = {
  children: PropTypes.node.isRequired
}

HeaderContainer.defaultProps = {
  children: undefined
}

export default HeaderContainer
