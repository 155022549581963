import React from "react"
import PropTypes from "prop-types"
import styles from "./DesktopOnly.module.scss"

const DesktopOnly = ({ children }) => {
  return <div className={styles.desktopOnly}>{children}</div>
}

DesktopOnly.propTypes = {
  children: PropTypes.node.isRequired
}

DesktopOnly.defaultProps = {
  children: undefined
}

export default DesktopOnly
