import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { Redirect } from 'react-router6-redirect'

import NotFound from 'layouts/NotFound'

import paths from './paths'

const auth = {
  isAuthenticated () {
    const token = window.localStorage.getItem('token')
    if (!token) {
      return false
    }
    return true
  }
}

const ApplicationRoutes = () => (
  <BrowserRouter>
    <Routes>
      {paths.map((route, i) => {
        if (route.isPrivate) {
          if (auth.isAuthenticated()) {
            return <Route key={i} {...route} />
          } else {
            return <Route key={i} path={route.path} element={<Navigate replace to="/login" />} />
          }
        }
        return <Route key={i} {...route} />
      })}
      <Route path="/agent/case/:id" element={<Redirect to="/agent/beta/:id" />} />
      <Route element={<NotFound />} />
    </Routes>
  </BrowserRouter>
)

export {
  paths
}

export default ApplicationRoutes
