import React from "react"
import { useAuth0 } from "@auth0/auth0-react"
import FooterLink from "components/FooterLink"
import FooterButton from "components/FooterButton"
import styles from "./Footer.module.scss"

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const AUTH0_RETURN = `${window.location.origin}`
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_KEY

const Footer = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0()

  const logoutAction = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("authUserId")
    window.location = `https://${AUTH0_DOMAIN}/v2/logout?returnTo=${AUTH0_RETURN}&client_id=${AUTH0_CLIENT_ID}`
  }

  return (
    <footer className={styles.footerContainer}>
      <nav>
        <FooterLink
          href="/how-simply-bail-works"
          text="How Simply Bail Works"
          displayBlock
        />
        <FooterLink href="/how-simply-bail-works#faq" text="FAQ" displayBlock />
        <FooterLink
          href="/how-bail-bonds-work"
          text="How Bail Bonds Work"
          displayBlock
        />
        <FooterLink
          href="mailto:info@simplybail.com"
          text="Contact Us"
          displayBlock
        />
        {!isAuthenticated && (
          <FooterButton onClick={loginWithRedirect} displayBlock>
            Bondsman Login
          </FooterButton>
        )}
        {isAuthenticated && (
          <FooterLink
            href="/agent/dashboard"
            text="Agent Dashboard"
            displayBlock
          />
        )}
        {isAuthenticated && (
          <FooterButton onClick={logoutAction} displayBlock>
            Log Out
          </FooterButton>
        )}
      </nav>
      <div className={styles.footerBottom}>
        <div className={styles.copyright}>
          <span>&copy; Copyright 2022 Simply Bail, LLC</span>
        </div>
        <div className={styles.legal}>
          <FooterLink href="/privacy" text="Privacy Policy" />
          <FooterLink href="/terms" text="Terms and Conditions" />
        </div>
      </div>
    </footer>
  )
}

export default Footer
