// src/Logout/Logout.js

import React, { Component } from 'react'

import Loading from 'components/Loading'

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_KEY

class Logout extends Component {
  componentDidMount() {
    localStorage.removeItem('authUserId')
    localStorage.removeItem('token')
    let authReturn = window.location.origin
    if (process.env.REACT_APP_NODE_ENV === 'production') {
      authReturn = 'https://simplybail.com'
    }
    window.location = `https://${AUTH0_DOMAIN}/v2/logout?returnTo=${authReturn}&client_id=${AUTH0_CLIENT_ID}`
  }
  render() {
    return <Loading text="Loggin out" />
  }
}

export default Logout