import React, { useEffect } from 'react'

import Loading from 'components/Loading'

const HomepageLayout = props => {
  useEffect(() => {
    if (process.env.REACT_APP_BUILD !== 'production') {
      window.location = `${window.location.origin}/callback`
    } else {
      window.location = 'https://simplybail.com'
    }
  }, [])

  return <Loading />
}

export default HomepageLayout