import { makeRequest } from './client'
import billMutations from './bill/mutations'
import billQueries from './bill/queries'

const bills = {
  payBill: (variables) => makeRequest(billMutations.PAY_BILL, variables),
  getBill: (variables) => makeRequest(billQueries.GET_BILL, variables),
  getBills: (variables) => makeRequest(billQueries.GET_BILLS, variables),
  getBillByToken: (variables) => makeRequest(billQueries.GET_BILL_BY_TOKEN, variables),
  deleteBill: (variables) => makeRequest(billMutations.DELETE_BILL, variables),
  sendBill: (variables) => makeRequest(billMutations.SEND_BILL, variables),
  createBill: (variables) => makeRequest(billMutations.CREATE_BILL, variables),
  updateBill: (variables) => makeRequest(billMutations.UPDATE_BILL, variables)
}

const methods = { bills }

export default methods