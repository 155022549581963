import React from "react"
import PropTypes from "prop-types"
import styles from "./HeaderRightContent.module.scss"

const HeaderRightContent = ({ children, className }) => {
  return <div className={`${styles.right} ${className}`}>{children}</div>
}

HeaderRightContent.propTypes = {
  children: PropTypes.node.isRequired,
  /**
   * any additional class names to apply
   */
  className: PropTypes.string
}

HeaderRightContent.defaultProps = {
  children: undefined,
  className: undefined
}

export default HeaderRightContent
