import { useCallback, useEffect, useState } from "react"
import { observer, inject } from "mobx-react"
import { useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet"

import { message } from 'antd'

import Loading from 'components/Loading'
import LayoutHomepage from "components/LayoutHomepage"
import HeaderLoggedOut from "components/HeaderLoggedOut"

import events from "lib/analytics"
import { defaultMetatags, discectError } from "lib/utils"
// import Gideons from 'components/Gideons'

const Home = observer(({ bondsmanStore, userStore }) => {
  const [isLoading, setIsLoading] = useState(0)

  const navigate = useNavigate()
  
  const [messageApi, contextHolder] = message.useMessage()

  const { bondsman } = bondsmanStore || {}

  const _setIsLoading = useCallback((val) => {
    setIsLoading(v => val ? v + 1 : v - 1)
  }, [])

  const _setError = useCallback((error) => {
    messageApi.open({
      type: 'error',
      content: error
    })
  }, [messageApi])

  useEffect(() => {
    const _fetch = async () => {
      try {
        const token = window.localStorage.getItem("token")
        const authUserId = window.localStorage.getItem("authUserId")
        if (token && authUserId) {
          await bondsmanStore.fetchBondsmanProfile()
        }
      } catch(e) {
        console.error(e)
        _setError(discectError(e))
      }
    }
    if (!bondsman) _fetch()
  }, [_setIsLoading, _setError, bondsman, bondsmanStore])

  const goUser = () => {
    events.frontDoorEvent()
    navigate("/register")
  }

  const goBondsman = () => {
    const { userStore } = this.props
    userStore.login()
  }

  const _getMetaTags = () => {
    return {
      title: "The fastest way to get someone out of jail",
      description:
        "Get your friend or loved one out of jail, without ever having to step foot in a jail or deal with the hassle of going to a bail bond agent’s office.",
      url: `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    }
  }

  return (
    <>
      {contextHolder}
      <HeaderLoggedOut />
      {defaultMetatags(_getMetaTags())}
      <Helmet>
        <link rel="canonical" href="https://simplybail.com" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <div className="template-home">
        {/* <Gideons page="Defendant Page" /> */}
        <LayoutHomepage
          goUser={goUser}
          goBondsman={goBondsman}
          userStore={userStore}
        />
      </div>
      {(isLoading > 0) && <Loading />}
    </>
  )
})

export default inject("bondsmanStore", "userStore")(Home)
