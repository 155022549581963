import React from 'react'

import styles from './styles.module.scss'

const Toggle = props => {
  const _onChange = e => {
    if (props.onChange) {
      setTimeout(() => {
        props.onChange(props.id, e.target.checked)
      }, 300)
    }
  }

  let classes = styles.canToggle__container
  if (props.isJoined) classes = `${classes} ${styles.canToggle__container__joined}`
  if (props.className) classes = `${classes} ${props.className}`

  let checkedValue = 'On'
  let unCheckedValue = 'Off'
  if (props.isYesNo) {
    checkedValue = 'Yes'
    unCheckedValue = 'No'
  } else if (props.isCustomValues) {
    checkedValue = props.isCustom[0]
    unCheckedValue = props.isCustom[1]
  }

  return (
    <div className={classes}>
      <label>{props.children || props.text}</label>
      <div className={styles.canToggle}>
        <input id={props.id} type="checkbox" name={props.name} defaultChecked={props.checked || false} onChange={_onChange} />
        <label htmlFor={props.id}>
          <div className={styles.canToggle__switch} data-checked={checkedValue} data-unchecked={unCheckedValue}></div>
        </label>
      </div>
    </div>
  )
}

export default Toggle