import React from "react"
import PropTypes from "prop-types"
import styles from "./HeaderUserInfo.module.scss"

const HeaderUserInfo = ({ token, name, bondsman }) => {
  return (
    <div className={styles.user}>
      <p>
        <a href={`/u/${token}`}>Click here</a> to view your case
        <span>
          {" "}
          for <strong>{name}</strong>
        </span>
        .
      </p>
      <div className={styles.bondsman}>
        {/* {bondsman && (
          <p>
            You do not have an new messages from <strong>{bondsman}</strong>.
          </p>
        )} */}
      </div>
    </div>
  )
}

HeaderUserInfo.propTypes = {
  token: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  bondsman: PropTypes.string
}

HeaderUserInfo.defaultProps = {
  token: undefined,
  name: undefined,
  bondsman: undefined
}

export default HeaderUserInfo
