import React from "react"
import PropTypes from "prop-types"
import styles from "./HeaderLink.module.scss"

const HeaderLink = ({ href, text, className }) => {
  return (
    <a className={`${styles.link} ${className}`} href={href}>
      {text}
    </a>
  )
}

HeaderLink.propTypes = {
  href: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  /**
   * any additional class names to apply
   */
  className: PropTypes.string
}

HeaderLink.defaultProps = {
  href: undefined,
  text: undefined,
  className: undefined
}

export default HeaderLink
