import React from "react"
import BodyClassName from "react-body-classname"

import HeaderLoggedOut from "components/HeaderLoggedOut"
import PropTypes from "prop-types"
import styles from "./Layout.module.scss"

const Layout = (props) => {
  const getClasses = () => {
    let bodyClassName = ""
    if (props.bodyClassName) bodyClassName = props.bodyClassName
    if (process.env.REACT_APP_BUILD !== "production")
      bodyClassName = `${bodyClassName} is-development`
    return bodyClassName
  }

  return (
    <BodyClassName className={getClasses()}>
      <>
        <HeaderLoggedOut />
        <div id="visualForm"></div>
        <main className={styles.main}>{props.children}</main>
      </>
    </BodyClassName>
  )
}

Layout.propTypes = {
  /**
   * any additional class names to apply to the body tag
   */
  bodyClassName: PropTypes.string,
  children: PropTypes.node.isRequired
}

Layout.defaultProps = {
  bodyClassName: undefined,
  children: undefined
}

export default Layout
