import { BILL_FRAGMENT } from './fragments'

const { gql } = require('graphql-request')

// Define the mutation
export const PAY_BILL = gql`
  mutation PayBill($id: ID!, $input: PayBillInput!) {
    payBill(id: $id, input: $input) {
      id
      isPaid
      invoiceTotalCents
      lineItems {
        id
        name
        valueCents
      }
      token
      tokenExpiry
      email
      phone
      lastSentDate
    }
  }
`

export const SEND_BILL = gql`
  mutation SendBill($id: ID!) {
    sendBill(id: $id) {
      ...billInfo
    }
  }
  ${BILL_FRAGMENT}
`

export const DELETE_BILL = gql`
  mutation DeleteBill($id: ID!) {
    deleteBill(id: $id)
  }
`

//input: {lineItems: {id: "", name: "", valueCents: 1500}, caseId: "", email: "", invoiceTotalCents: 1500, name: "", phone: ""}
export const CREATE_BILL = gql`
  mutation CreateBill($input: CreateBillInput!) {
    createBill(input: $input) {
      ...billInfo
    }
  }
  ${BILL_FRAGMENT}
`

// id: "", input: {lineItems: {id: "", name: "", valueCents: 1500}, caseId: "", email: "", invoiceTotalCents: 1500, name: "", phone: ""}
export const UPDATE_BILL = gql`
  mutation UpdateBill($id: ID!, $input: UpdateBillInput!) {
    updateBill(id: $id, input: $input) {
      ...billInfo
    }
  }
  ${BILL_FRAGMENT}
`

const mutations = {
  PAY_BILL,
  SEND_BILL,
  DELETE_BILL,
  UPDATE_BILL,
  CREATE_BILL
}

export default mutations