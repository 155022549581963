import React from 'react'
import ReactDOM from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { Integrations } from "@sentry/tracing"
import App from './layouts/App'
import reportWebVitals from './reportWebVitals'

Sentry.init({
  dsn: "https://957086ebcb4f4356a86fc96b6f643085@o431895.ingest.sentry.io/5383830",
  environment: process.env.REACT_APP_SENTRY_ENV,
  debug: false,
  integrations: [
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    const error = hint?.originalException || {}
    if (error?.message?.toLowerCase().includes("jwt expired")) {
      return null
    }
    return event
  }
})

// toggle logging
if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', `${process.env.REACT_APP_LOGGER_NAME}:*`)
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(<App />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
