import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import states from 'lib/states'

const InputSelectState = props => {
  const [selectedState, setSelectedState] = useState(null)

  useEffect(() => {
    if (props.defaultState) setSelectedState(props.defaultState) 
  }, [props.defaultState])

  const onChange = val => {
    setSelectedState(val.value)
    if (props.onChange) props.onChange(val)
  }

  const options = Object.entries(states).map(([k, v], key) => {
    return { value: k, label: v }
  })

  let val = null
  if (selectedState) {
    val = options.find(opt => opt.value === selectedState)
  }

  return <Select
    id="state"
    value={val}
    onChange={val => onChange(val)}
    options={options}
    placeholder={props.placeholder || "Choose a State"}
    autoFocus={props.autoFocus || false}
    closeMenuOnSelect={true}
    styles={{
      menu: provided => ({ ...provided, zIndex: 9999 })
    }}
  />
}

export default InputSelectState