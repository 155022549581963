import { GraphQLClient } from 'graphql-request'

const REST_API_URI = process.env.REACT_APP_REST_API_URI

// GraphQL endpoint
const endpoint = `${REST_API_URI}/graphql`

const getAccessTokenHeaders = () => {
  const token = window.localStorage.getItem('token')
  const authUserId = window.localStorage.getItem('authUserId')
  if (process.env.REACT_APP_NODE_ENV === 'local' && process.env.REACT_APP_LOCAL_USER) {
    return {
      'Authorization': `Bearer BLARGH`,
      'X-User-ID': process.env.REACT_APP_LOCAL_USER,
      'X-SimplyBail-App-Id': 'RETAIL'
    }
  }
  if (token && authUserId) {
    try {
      return {
        'Authorization': `Bearer ${token}`,
        'X-User-ID': authUserId,
        'X-SimplyBail-App-Id': 'RETAIL',
        'X-Service-Id': process.env.REACT_APP_WAREHOUSE_SERVICE_ID
      }
    } catch(err) {
      console.error(err)
    }
  }

  return {}
}

// a function to create a new client with the latest token
export const createGraphQLClient = () => {
  const headers = getAccessTokenHeaders()
  return new GraphQLClient(endpoint, {
    headers: { ...headers }
  })
}

//  usage
export const makeRequest = async (query, variables) => {
  const client = createGraphQLClient()
  try {
    return client.request(query, variables)
  } catch (error) {
    console.error('GraphQL Error:', error)
    throw error
  }
}

const client = {
  createGraphQLClient,
  makeRequest
}

export default client