import * as React from 'react'
import { Provider } from 'mobx-react'
import { Auth0Provider } from "@auth0/auth0-react"
import { ConfigProvider } from 'antd'
import stores from 'stores'
import Routes from 'routes'
// import events from 'lib/analytics'

import 'styles/App.scss'

const App = () => {

  let redirect = `${window.location.origin}/callback`
  
  return (
    <Provider {...stores}>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_KEY}
        // useRefreshTokens={true}
        authorizationParams={{
          redirect_uri: redirect,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE
        }}
      >
        <ConfigProvider
          theme={{
            token: {
              // Seed Token
              colorPrimary: '#24c0ac'
            }
          }}
        >
          <Routes />
        </ConfigProvider>
      </Auth0Provider>
    </Provider>
  )
}

export default App
